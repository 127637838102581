.back-button {
  display: flex;
  justify-content: center;

  background-color: #F0EFEB;
  border-top: 2px solid black;
  color: black;
  font-size: 24px;
  font-weight: bold;
  padding: 8px 0;
  text-decoration: none;
  width: 100%;
}
