.home .tools {
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 40px;
}

.home a {
  display: block;
  text-decoration: none;
  font-size: 24px;
  color: black;
  border: 1px solid black;
  background-color: #f0efeb;
  padding: 10px 30px;
  border-radius: 8px;
  overflow: hidden;
}

.home a:hover {
  background-color: white;
}

@media (min-width: 520px) {
  .home {
    margin-left: calc(50% - 250px);
    margin-right: calc(50% - 250px);
  }
}