.flashcard-app {
  display: grid;
  grid-template-rows: 100px 1fr 75px;
  height: 100vh;
  box-sizing: border-box;
}

.deck-gallery {
  padding: 10px;
}
