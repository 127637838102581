.multiple-choice-flashcard {
  height: calc(100% - 40px);

  background-color: #f0efeb;
  border: 4px solid #003049;
  border-radius: 10px;
  transition: border 2s, box-shadow 2s;

  box-sizing: border-box;

  user-select: none;

  overflow: hidden;
  overflow-x: auto;

  width: 100%;
  margin-left: 5px;
  margin-right: 5px;

  display: grid;
  grid-template:
    "repeat-1 repeat-3 .        duration"
    "question question question question"
    "choices  choices  choices  choices";
  grid-template-columns: min-content min-content 1fr min-content;
  grid-template-rows: min-content .25fr 1fr;
}

.multiple-choice-flashcard .repeat-button {
  background-color: #f0efeb;
  border: none;
  border-bottom: 2px solid #003049;
  border-right: 2px solid #003049;
  cursor: pointer;
  font-size: 28px;
  padding: 10px 20px;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  padding: 0 15px;
}

.multiple-choice-flashcard .repeat-button img {
  max-width: 35px;
}

.multiple-choice-flashcard .repeat-button:active {
  background-color: #003049;
  color: #f0efeb;
}

.multiple-choice-flashcard .repeat-button:focus {
  outline: none;
}

.multiple-choice-flashcard .repeat-1 {
  grid-area: repeat-1;
}

.multiple-choice-flashcard .repeat-3 {
  grid-area: repeat-3;
}

.multiple-choice-flashcard .duration {
  grid-area: duration;
  padding: 15px;
  text-align: right;
}

.multiple-choice-flashcard .question {
  grid-area: question;
  margin: 0;
  color: #003049;
  font-family: Quicksand;
  font-size: calc(32px + .5vw);
  font-weight: bold;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.multiple-choice-flashcard .choices {
  grid-area: choices;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow-x: auto;
  align-items: center;
  gap: 5px;
}

.multiple-choice-flashcard .choices button {
  color: #003049;
  font-family: Quicksand;
  font-size: calc(22px + .5vw);
  font-weight: bold;
  text-align: center;
  padding: 14px 60px;
  border-radius: 8px;
  min-width: 60%;
}

.multiple-choice-flashcard.wrong {
  animation-duration: 0.25s;
  animation-name: wiggle;
  animation-timing-function: ease-in;
}

@media (min-width: 1000px) {
  .multiple-choice-flashcard {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media (min-height: 800px) {
  .multiple-choice-flashcard {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@keyframes wiggle {
   0% { transform: translateX(-10px); }
   10% { transform: translateX(10px); }
   20% { transform: translate(-10px); }
   30% { transform: translate(10px); }
   40% { transform: translate(-10px); }
   50% { transform: translate(10px); }
   60% { transform: translate(-10px); }
   70% { transform: translate(10px); }
   80% { transform: translate(-10px); }
   90% { transform: translate(10px); }
   100% { transform: translate(0); }
}