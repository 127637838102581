.flashcard {
  height: calc(100% - 80px);

  background-color: #f0efeb;
  border: 4px solid #003049;
  border-radius: 10px;
  box-sizing: border-box;

  margin: 5px;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;

  cursor: pointer;
  user-select: none;

  overflow: hidden;
  overflow-x: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  display: grid;
  grid-template:
    "repeat-1 repeat-3 ."
    "content  content  content";
  grid-template-columns: min-content min-content 1fr;
  grid-template-rows: min-content 1fr;
}

.flashcard .repeat-button {
  background-color: #f0efeb;
  border: none;
  border-bottom: 2px solid #003049;
  border-right: 2px solid #003049;
  cursor: pointer;
  font-size: 28px;
  padding: 10px 20px;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  padding: 0 15px;
}

.flashcard .repeat-button img {
  max-width: 35px;
}

.flashcard .repeat-button:active {
  background-color: #003049;
  color: #f0efeb;
}

.flashcard .repeat-button:focus {
  outline: none;
}

.flashcard .content {
  grid-area: content;
}

.flashcard img.content {
  margin: 0;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 30px;
  max-height: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.flashcard p.content {
  color: #003049;
  font-family: Quicksand;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  margin: 0;
  font-size: 18vw;
  line-height: 0;
}

@media (min-width: 1000px) {
  .flashcard {
    margin-left: 50px;
    margin-right: 50px;
  }
}